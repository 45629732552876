<template>
  <section class="container mx-auto py-6 md:py-10">
    <h2 class="section-heading text-center md:text-left">
      Idea Creates, Expert Inspires Your Brand
    </h2>
    <div
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-8 gap-y-14 pt-8 pb-10"
    >
      <template v-if="isLoading">
        <div v-for="i in 4" :key="i">
          <UtilsSkeletonLoading class="rounded-lg h-40 w-full mb-4 card-img" />
          <UtilsSkeletonLoading class="w-full h-14 mb-3" />
          <UtilsSkeletonLoading class="w-full h-12" />
        </div>
      </template>

      <template v-else>
        <ArticleCard
          v-for="content in contentsData"
          :key="content.id"
          :with-logo="false"
          :title="content.attributes?.title"
          :desc="content.attributes?.layout_component?.[0]?.description"
          :image-url="content.attributes?.image?.data?.attributes?.url"
          :slug="`/ideas/${content.attributes?.slug}`"
        />
      </template>
    </div>
    <div class="mx-auto text-center">
      <CommonViewAllButton url="/ideas" page-name="Ideas" />
    </div>
  </section>
</template>

<script setup>
const isLoading = ref(true)

const contentsData = ref([])

const getContents = async () => {
  const { data, error, pending } = await useSuitmediaApiData('/api/contents', {
    query: {
      'pagination[pageSize]': 4,
      populate:
        'image,layout_component,layout_component.type,study_case_client.image',
      'filters[$and][0][layout][$eq]': 'article',
      sort: 'publish_at:desc'
    }
  })
  isLoading.value = pending.value

  if (error.value) {
    throw createError({
      statusCode: 404,
      message: `Case Studies does not exist`
    })
  }

  return data.value
}

const contents = await getContents()
contentsData.value = contents.data
</script>

<style lang="scss" scoped>
.card-img {
  position: relative;
  padding-bottom: calc(210 / 373) * 100%;
  display: block;
}
</style>
